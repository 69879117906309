.logo{
    padding: 8px 16px; 
	/* position:fixed;  */
	/* top:0;  */
    text-align: left;
}

.nav-bar {
    width: 100%;
    height: 70px;
    /* background-color: coral; */
}
