.formCard {
    margin: 10px;
    margin-bottom: 30px;
    width: 90%;
    max-width: 800px;
    padding: 20px;
    border-radius: 15px 50px;
    background-color: blueviolet;
}

.optionButton {
    margin: 8px 5px;
}

textarea {
    width: 100%;
}

.margin-bot {
    margin-bottom: 8px;
}

.no-margin {
    margin: 0;
}

input, textarea {
    width: 100%;
    padding: 8px;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }  